<template>
  <span
    class="pz__drag-handle d-inline-block"
    :class="{ 'drag--disabled': !draggable }"
  >
    <feather-icon icon="MenuIcon" size="16" />
  </span>
</template>

<script>
export default {
  props: {
    draggable: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss">
.pz__drag-handle {
  color: #737373;
  cursor: move; // Fallback if `cursor: grab` is unsupported
  cursor: grab;

  &.drag--disabled {
    visibility: hidden;
  }
}
</style>
